import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            headerClass: "ani",
        },
    },
    {
        path: "/company",
        name: "company",
        component: () => import("../views/CompanyView.vue"),
        meta: {
            headerClass: "noneAni",
        },
    },
    {
        path: "/news",
        name: "news",
        component: () => import("../views/NewsView/NewsView.vue"),
        meta: {
            headerClass: "noneAni",
        },
    },
    {
        path: "/news/newsletter/:id",
        name: "newsletter",
        component: () => import("../views/NewsView/NewsLetterView.vue"),
        props: true,
        meta: {
            headerClass: "noneAni",
        },
    },
    {
        path: "/reference",
        name: "reference",
        component: () => import("../views/ReferenceView.vue"),
        meta: {
            headerClass: "noneAni",
        },
    },
    {
        path: "/solution/ai",
        name: "artificial intelligence",
        component: () => import("../views/SolutionView/AiView.vue"),
        meta: {
            headerClass: "noneAni",
        },
    },
    {
        path: "/solution/bigdata",
        name: "bigdata",
        component: () => import("../views/SolutionView/BigDataView.vue"),
        meta: {
            headerClass: "noneAni",
        },
    },
    {
        path: "/solution/cloud",
        name: "cloud",
        component: () => import("../views/SolutionView/CloudView.vue"),
        meta: {
            headerClass: "noneAni",
        },
    },
    {
        path: "/solution/dtn",
        name: "dtn",
        component: () => import("../views/SolutionView/DtnView.vue"),
        meta: {
            headerClass: "noneAni",
        },
    },
    {
        path: "/notice",
        name: "notice",
        component: () => import("../views/BidView/BidView.vue"),
        meta: {
            headerClass: "noneAni",
        },
    },
    {
        path: "/notice/noticelist/:id",
        name: "noticelist",
        component: () => import("../views/BidView/BidNotice.vue"),
        props: true,
        meta: {
            headerClass: "noneAni",
        },
    },
    {
        path: "*",
        name: "notFound",
        component: () => import("../views/NotFoundView/NotFound.vue"),
        meta: {
            headerClass: "noneAni",
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { y: 0 };
    },
});

export default router;
