<template>
    <section class="history">
      <div class="container">
        <div class="scrollWrap">
          <div class="yearChange">
            <h1 class="tit">OUR HISTORY</h1>
            <p>20<span ref="num">{{ this.changeNum }}</span></p>
          </div>
          <ul class="historyContents">
            <li v-for="(history, year) in historys" ref="history" :key="year">
              <ul>
                <li v-for="(diary, month) in history.road" :key="month">{{ diary.list }}</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </section>
</template>

<script>
import { mapState } from 'vuex';

const homeStore = 'homeStore'

export default {
  name: 'OurHistory',
  data(){
    return {
      changeNum: 23,
      numdata: 23,
      translate: false,
      scroll: window.scrollY || window.pageYOffset
    }
  },
  mounted(){
    window.addEventListener('scroll', this.yearsChange);
  },
  destroyed(){
    window.removeEventListener('scroll', this.yearsChange);
  },
  methods: {
    // 히스토리 섹션에서 연혁 범위에 따라 연도가 바뀌는 모션 활성화
    yearsChange(){
      const className = document.querySelector('.translated-ltr');
      if(className === null) this.translate = false
      else this.translate = true;
      const posArr = [];
      const historys = this.$refs.history;
      const num = this.$refs.num;
      const historysArr = Array.from(historys);
      for(let el of historysArr){
        const elPos = el.getBoundingClientRect();
        const startPos = elPos.top;
        const baseLine = 650;
        posArr.push(this.scroll + startPos - baseLine);
      }

      for(let [index] of historysArr.entries()){
        if(this.scroll >= posArr[index]){
          this.changeNum = 23 - [index];
          if(this.translate === true){
            num.children[0].children[0].innerHTML = this.changeNum;
          }else{
            this.changeNum = 23 - [index];
          }
        }
      }
    }
  },
  computed: {
    ...mapState(homeStore, ['historys']),
  },
}
</script>

<style lang="scss" scoped>
  .history {
    padding: 150px 0 180px;

    .container {
      .scrollWrap {
        display: flex;
        justify-content: space-between;
        position: relative;
        height: 100%;

        .yearChange {
          width: 50%;
          height: fit-content;
          position: sticky;
          top: 300px;
          left: 0px;

          p {
            color: #fff;
            font-size: 10rem;
            font-weight: bold;
            display: flex;

            span {
              color: #FE6500;
            }
          }
        }

        .historyContents {
          max-width: 725px;
          margin: 9rem 0 4.5rem;

          >li {
            padding: 40px 0 200px 20px;

            &:last-child {
              padding-bottom: 0px;
            }

            ul li {
              list-style-type: disc;
              color: #fff;
              line-height: 1.65rem;
            }
          }
        }
      }
    }
  }

  /* TABLET */
  @media screen and (max-width: 1280px) {
    .history {
      padding: 100px 0;

      .container {
        .scrollWrap {
          flex-direction: column;

          .yearChange {
            width: 100%;
            top: 0px;
            padding-top: 100px;
            background: #060606;

            h1 {
              margin-bottom: 0px;
              margin-top: 20px;
            }

            p {
              font-size: 7.5rem;
            }
          }
        }
      }
    }
  }

  /* MOBILE */
  @media screen and (max-width: 767px) {
    .history {
      .container {
        .scrollWrap {
          .yearChange {
            h1 {
              margin-bottom: 30px;
            }

            p {
              font-size: 5rem;
            }
          }

          .historyContents {
            margin: 4rem 0;

            >li {
              padding: 40px 0 250px 20px;
            }
          }
        }
      }
    }
  }
</style>