const homeStore = {
    namespaced: true,
    state: {
        historys: [
            {
                year: 2023,
                road: [
                    { list: "남양주 ITS 스마트 교차로 운영 시스템" },
                    { list: "광양시 ITS 스마트 교차로 운영 시스템 고도화" },
                    { list: "시계열 AI NPU과제_정보통신기획평가원" },
                    { list: "K-클라우드 AI반도체 NPU기반 응용서비스 실증과제_정보통신산업진흥원" },
                    { list: "6월, 스마트 교차로 시스템 TASv1.0 기본성능평가 최상급(교통량, 차종분류, 대기행렬 교통량: 최상급 인증)_한국건설기술연구원" },
                    { list: "7월, 직접생산확인(정보시스템개발서비스)_중소기업유통센터" },
                    { list: "7월, 소프트웨어품질인증 1등급(LAON TASv1.0)_한국정보통신기술협회" },
                    { list: "8월, 강화학습 기반 신호 제어 장치 및 신호 제어 방법 중국 특허 등록" },
                ],
            },
            {
                year: 2022,
                road: [
                    { list: "12월, 스마트횡단보도 실증지원사업(성남시) 완료" },
                    { list: "12월, NIA Cloud 과제 주관(TMaaS1.0 개발)" },
                    { list: "9월, 성남시 ITS 1차 구축 사업 준공" },
                    { list: "8월, 성남시 스마트 횡단보도" },
                    { list: "8월, 안양시 2차 ITS 사업" },
                    { list: "8월, NIA Cloud 과제" },
                    { list: "8월, NIA 58번 데이터 구축 과제" },
                    { list: "8월, NIA 55번 데이터 구축 과제" },
                    { list: "6월, 제2기 KB유니콘클럽 선정(경기창조경제혁신센터 주관)" },
                    { list: "6월, AI데이터 구축 과제 참여(NIA 주관)" },
                    { list: "4월, 안양시 ITS 2차 구축 사업 참여" },
                    { list: "3월, 경기 성남시 ITS 스마트교차로 시스템" },
                    { list: "3월, 전남 광양시 ITS 스마트교차로 시스템" },
                    { list: "1월, 한국도로공사 국도ITS 스마트CCTV 2차검증 시스템 준공" },
                ],
            },
            {
                year: 2021,
                road: [
                    { list: "11월, (주)라온로드 물적 분할 설립" },
                    { list: "8월, 국도 ITS 스마트 CCTV 2차 검증체계 및 AI 학습체계 구축 사업" },
                    { list: "8월, 경기 화성시 지능형교통시스템 (ITS) 구축사업 스마트교차로 시스템" },
                    { list: "6월, AI 학습용 데이터 구축 사업 교통물류 분야 교차로 신호 복합 데이터 총괄 수행" },
                    { list: "5월, 부산시 스마트횡단보도 구축" },
                    { list: "4월, 인천대교 돌발상황 검지 레이터-영상 복합 시스템 개발" },
                    { list: "3월, 국도 ITS 통합시스템 확대구축 실시설계(ISMP)" },
                ],
            },
            {
                year: 2020,
                road: [
                    { list: "10월, NIPA 5G 기반 지능형 교통 IoT필드테스트 인프라 구축" },
                    { list: "10월, 세종시 2019년도 스마트시티 국가시범도시 리빙랩형 스마트 모빌리티 종합실증사업 중 스마트 횡단보도 서비스 시스템" },
                    { list: "9월, 경기 안양시 지능형 교통시스템(ITS) 구축사업 스마트교차로 시스템" },
                    { list: "8월, AI 학습용 데이터 구축 사업 교통안전 분야 총괄 수행" },
                    { list: "7월, 돌발상황 검지시스템 성능평가 최상급" },
                    { list: "6월, 광주광역시 차세대 자율협력 지능형 교통체계(C-ITS) 실증사업 보행자 안전 시스템" },
                    { list: "6월, AI 스마트 교차로 지능형 신호제어 경찰청 교통안전심의(2차)" },
                    { list: "5월, 보행자 및 차량 검출을 위한 자체 딥러닝 네트워크 모델 개발(고속 동작 + 고정밀 검출)" },
                    { list: "3월, 휴대 거치형 교차로 교통정보 분석 시스템(LaonRoad TS 개발)" },
                ],
            },
            {
                year: 2019,
                road: [
                    { list: "12월, 경기 안양시 인덕원사거리 실시간 신호 제어 시범 운영. 제어지체 15% 개선" },
                    { list: "12월, 차량검지장치(영상식 VDS) 성능 평가 최상급" },
                    { list: "11월, 국내 최초 AI Embadded 교통 카메라 출시" },
                    { list: "10월, AI 스마트 교차로 지능형 신호제어 경찰청 교통안전심의(1차)" },
                    { list: "9월, 국제도로박람회(ROTREX) 전시회 출품(지능형 신호제어, 스마트 교차로)" },
                    { list: "8월, ITS 2019 중소기업혁신대전 출품(AI 영상검지기를 이용한 교차로 혼잡도 분석)" },
                    { list: "7월, 필리핀 마닐라 고속도로 교통통계 솔루션 구축 제휴(NLEX Ventures)" },
                    { list: "6월, “중동 ITS 로드쇼 2019” 참가(두바이, 카타르)" },
                    { list: "5월, AI 영상검지기를 이용한 표준감응신호제어(교통신호제어기 옵션보드 연계) 솔루션 개발" },
                ],
            },
            {
                year: 2018,
                road: [{ list: "11월, 독일 슈투트가르트 전시회 교통 솔루션 출품(차로 별 대기차량 AI분석)" }, { list: "7월, 라온피플 교통솔루션 개발 착수" }],
            },
        ],
        licenses: [
            {
                link: "/company",
                img: require("@/assets/images/202312181.png"),
                title: "강화학습 기반 신호 제어 장치 및 신호 제어 방법 미국 특허 등록 완료",
                date: "2023-12-14",
            },
            {
                link: "/company",
                img: require("@/assets/images/20231218.png"),
                title: "강화학습 기반 신호 제어 장치 및 신호 제어 방법 미국 특허 등록",
                date: "2023-12-14",
            },
            {
                link: "/company",
                img: require("@/assets/images/LPP190004-9PCT-CN.png"),
                title: "강화학습 기반 신호 제어 장치 및 신호 제어 방법 중국 특허 등록",
                date: "2023-08-18",
            },
            {
                link: "/company",
                img: require("@/assets/images/20230126.png"),
                title: "GS인증 AI 영상분석 시스템 1등급",
                date: "2023-07-03",
            },
            {
                link: "/company",
                img: require("@/assets/images/20230628.png"),
                title: "스마트교차로 시스템(SIS) 성능평가 성적서 최상급",
                date: "2023-06-28",
            },
            {
                link: "/company",
                img: require("@/assets/images/20230601.png"),
                title: "주식회사 라온로드 벤처기업확인서",
                date: "2023-06-01",
            },
            {
                link: "/company",
                img: require("@/assets/images/20230126.png"),
                title: "강화학습 기반 신호 제어 장치 및 신호 제어 방법",
                date: "2023-01-26",
            },
            {
                link: "/company",
                img: require("@/assets/images/20221223.png"),
                title: "클라우드 서비스 확인서",
                date: "2022-12-23",
            },
            {
                link: "/company",
                img: require("@/assets/images/20211022.jpeg"),
                title: "교통신호 연산 방법 및 이를 수행하기 위한 장치",
                date: "2021-10-22",
            },
            {
                link: "/company",
                img: require("@/assets/images/20200907_2.jpeg"),
                title: "영상 검지 장치 및 이를 포함하는 신호 제어 시스템 및 신호 제어 방법",
                date: "2020-09-07",
            },
            {
                link: "/company",
                img: require("@/assets/images/20200907_1.jpeg"),
                title: "영상 검지 장치 및 이를 포함하는 신호 제어 시스템 및 신호 제어 방법",
                date: "2020-09-07",
            },
            {
                link: "/company",
                img: require("@/assets/images/20200907.jpeg"),
                title: "강화학습 기반 신호 제어 장치 및 신호 제어 방법",
                date: "2020-09-07",
            },
            {
                link: "/company",
                img: require("@/assets/images/20200811.jpeg"),
                title: "교통신호 제어 장치 및 방법",
                date: "2020-08-11",
            },
            {
                link: "/company",
                img: require("@/assets/images/20200701.jpeg"),
                title: "교통상황 정보 제공 장치 및 방법",
                date: "2020-07-01",
            },
        ],
    },
};

export default homeStore;
