<template>
  <footer>
    <div class="container">
      <ul>
        <li>(주)라온로드</li>
        <li>대표이사: 이석중</li>
        <li>주소: 경기도 과천시 과천대로7나길 60, C동 5층 505호(갈현동, 과천어반허브)</li>
        <br />
        <li>Tel: 02-507-8207</li>
        <li>E-mail: sales@laonroad.com</li>
      </ul>

      <span class="copy"
        >COPYRIGHTS 2022 LAONROAD INC. ALL RIGHTS RESERVED</span
      >
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  background: #060606;
  display: flex;
  justify-content: center;
  padding: 0px 10%;

  .container {
    width: 90%;
    margin: 0 auto;
    padding: 30px 0px;

    ul {
      margin-bottom: 30px;

      li {
        display: inline-block;
        margin-right: 30px;
        font-size: 0.9rem;
        font-weight: 300;
        color: rgba(255, 255, 255, 0.8);
      }
    }

    .copy {
      color: rgba(255, 255, 255, 0.5);
      font-size: 0.75rem;
      font-weight: 200;
      letter-spacing: 1px;
    }
  }
}
</style>
