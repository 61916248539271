<template>
    <div v-swiper="swiperOption">
        <h2>LICENSE</h2>
        <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(license, numbering) in licenses" :key="numbering">
                <a>
                    <div class="imgBox"><img :src="license.img" alt="특허이미지" /></div>
                    <h3>{{ license.title }}</h3>
                    <span>{{ license.date }}</span>
                </a>
            </div>
        </div>
        <div class="naviBox">
            <div slot="button-prev" class="swiper-button-prev"><font-awesome-icon icon="fa-solid fa-arrow-left-long" /></div>
            <div slot="button-next" class="swiper-button-next"><font-awesome-icon icon="fa-solid fa-arrow-right-long" /></div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { mapState } from "vuex";

Vue.use(VueAwesomeSwiper);

const homeStore = "homeStore";

export default {
    name: "LicenseList",
    data() {
        return {
            swiperOption: {
                loop: true,
                spaceBetween: 30,
                autoplay: {
                    delay: 5000,
                },
                breakpoints: {
                    280: {
                        slidesPerView: 1,
                    },
                    370: {
                        slidesPerView: 1.3,
                    },
                    490: {
                        slidesPerView: 2,
                    },
                    800: {
                        slidesPerView: 3,
                    },
                    1100: {
                        slidesPerView: 4,
                    },
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            },
        };
    },
    computed: {
        ...mapState(homeStore, ["licenses"]),
    },
};
</script>

<style lang="scss" scoped>
/* DESKTOP */
.swiper-container {
    h2 {
        color: #fff;
        font-size: 1.2rem;
        margin-bottom: 30px;
    }

    .swiper-wrapper {
        .swiper-slide {
            border-radius: 6px;
            background: #444;
            padding: 30px 20px;

            a {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                cursor: auto;

                .imgBox {
                    width: 100%;
                    height: 200px;
                    margin-bottom: 20px;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        border: none;
                    }
                }

                h3 {
                    text-align: center;
                    color: #fff;
                    margin-bottom: 20px;
                    min-height: 91px;
                }

                span {
                    font-size: 0.8rem;
                    font-weight: 200;
                    text-align: center;
                    color: rgba(255, 255, 255, 0.9);
                    letter-spacing: 1px;
                }
            }

            &.swiper-slide-active {
                background: #fe6500;
            }
        }
    }

    .naviBox {
        position: absolute;
        top: 13px;
        right: 0px;
        width: 100px;

        .swiper-button-prev::after,
        .swiper-button-next::after {
            display: none;
        }

        .swiper-button-prev svg,
        .swiper-button-next svg {
            font-size: 1.5rem;
            color: #fff;
            transition: 0.3s;

            &:hover {
                color: #fe6500;
            }
        }
    }
}

/* MOBILE */
@media screen and (max-width: 480px) {
    .swiper-container {
        .swiper-wrapper {
            .swiper-slide {
                padding: 20px 15px;
            }
        }
    }
}
</style>
