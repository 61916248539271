<template>
  <div id="home">
    <!-- Visual -->
    <figure id="visual">
      <video
        class="vids"
        autoplay
        loop
        muted
        playsinline
        poster="@/assets/images/poster.jpg"
        src="@/assets/videos/video.mp4"
      ></video>
      <div class="container">
        <div class="title">
          <h1>ROAD TO A BETTER <span>FUTURE</span></h1>
          <p>We make mobility safer, more efficient, and more sustainable</p>
        </div>

        <div class="scrollDown">
          <span>SCROLL DOWN</span>
          <div class="lineBox">
            <div class="line"></div>
          </div>
        </div>
      </div>
    </figure>

    <!-- Company -->
    <OurCompany />

    <!-- Solution -->
    <section id="solution">
      <div class="container">
        <Solution-List />
      </div>
      <div class="bgLogo">
        <div ref="bgWrap" class="bgWrap">
          <span>LAON ROAD</span>
          <span>LAON ROAD</span>
        </div>
      </div>
    </section>

    <!-- History -->
    <OurHistory />

    <!-- Record -->
    <section id="record">
      <div class="container">
        <h1 class="tit2"><span>LAONROAD의 증명된 가치</span>를 확인하세요.</h1>
        <div class="license">
          <License-List />
        </div>
        <div class="certification">
          <h2>CERTIFICATION</h2>
          <ul class="listTable">
            <li>
              <p>한국지능형교통체계협회</p>
              <h3>돌발상황 검지시스템 성능평가 [최상급]</h3>
              <span>2020-07-17</span>
            </li>
            <li>
              <p>한국건설기술연구원</p>
              <h3>차량검지장치(영상식 VDS) 성능평가 [최상급]</h3>
              <span>2019-19-11</span>
            </li>
          </ul>
        </div>
      </div>
    </section>

    <!-- News -->
    <section id="news">
      <div class="container">
        <h1 class="tit2"><span>LAONROAD의 주요 소식</span>을 확인하세요.</h1>

        <Link-Box v-bind="{ text: 'VIEW MORE', link: '/news' }" />

        <ul class="listTable">
          <li v-for="(letter, index) in newsletters.slice(0, 5)" :key="index">
            <span class="listNum" v-text="GetNumber - index < 10 ? `0${GetNumber - index}` : GetNumber - index"></span>
            <router-link :to="{ name: 'newsletter',
                params: {
                  id: letter.id,
                  date: letter.date,
                  title: letter.title,
                  content: letter.content,
                  image: letter.image,
                  orLink: letter.orLink,
                },}">{{ letter.title }}</router-link >
            <span class="date">{{ letter.date }}</span>
          </li>
        </ul>
      </div>
    </section>
  </div>
</template>

<script>
import LicenseList from "@/components/LicenseList.vue";
import SolutionList from "@/components/SolutionList.vue";
import LinkBox from "@/components/LinkBox.vue";
import OurCompany from "@/components/company/OurCompany.vue";
import OurHistory from "@/components/company/OurHistory.vue";
import { mapGetters } from "vuex";

const newsroomStore = "newsroomStore";

export default {
  name: "HomeView",
  components: {
    LicenseList,
    SolutionList,
    LinkBox,
    OurCompany,
    OurHistory,
  },
  data() {
    return {
      scroll: window.scrollY || window.pageYOffset,
      newsletters: JSON.parse(localStorage.getItem("newsletters")) || "",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollEvent);
    localStorage.setItem(
      "newsletters",
      JSON.stringify(this.$store.state.newsroomStore.newsletters)
    );
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollEvent);
  },
  methods: {
    // 솔루션 섹션의 .bgLogo(배경)이 스크롤 위치값에 따라 좌우로 움직이는 모션 활성화
    scrollEvent() {
      const bgWrap = this.$refs.bgWrap;
      const bgPos = bgWrap.getBoundingClientRect();
      const startPos = bgPos.top;
      const baseLine = 800;
      const bgScroll = this.scroll + startPos - baseLine;

      if (this.scroll > bgScroll) {
        bgWrap.style.transform = `translateX(-${this.scroll - bgScroll}px)`;
      }
    },
  },
  computed: {
    ...mapGetters(newsroomStore, ["GetNumber"]),
  },
};
</script>

<style lang="scss" scoped>
#home {
  width: 100%;

  #visual {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: relative;
    background: #060606;

    .vids {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .container {
      &::before {
        content: "";
        display: block;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.35);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .title {
        width: 100%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%);
        text-align: center;
        opacity: 0;
        animation: titleUp 1s 0.5s ease forwards;

        h1 {
          font-size: 5rem;
          color: #fff;

          span {
            color: #fe6500;
          }
        }

        p {
          font-size: 2rem;
          color: #fff;
        }
      }

      .scrollDown {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        opacity: 0;
        animation: delayShow 1s 1.6s ease forwards;

        span {
          color: #fff;
          font-size: 0.8rem;
          font-weight: 200;
          letter-spacing: 1px;
        }

        .lineBox {
          margin-top: 10px;
          width: 2px;
          height: 120px;
          background: rgba(255, 255, 255, 0.5);

          .line {
            width: 2px;
            height: 50px;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(255, 255, 255, 0.8);
            animation: lineDown 1.5s infinite;
          }
        }
      }
    }
  }

  #solution {
    padding: 140px 0 180px;
    position: relative;

    .container {
      z-index: 1;
    }

    .bgLogo {
      width: 100%;
      overflow: hidden;
      position: absolute;
      bottom: 0px;
      left: 0px;

      .bgWrap {
        width: 500%;

        span {
          margin: 0 20px;
          font-size: 19rem;
          font-weight: 900;
          color: rgba(255, 255, 255, 0.05);
        }
      }
    }
  }

  #record {
    padding: 100px 0 180px;
    background: #060606;

    .container {
      .license {
        margin-bottom: 100px;
      }

      .certification {
        h2 {
          color: #fff;
          font-size: 1.2rem;
          margin-bottom: 30px;
        }
      }
    }
  }

  #news {
    padding: 100px 0 180px;
    background: #060606;

    .container {
      .linkBox {
        top: 15px;
        right: 0px;
      }
    }
  }
}

/* TABLET */
@media screen and (max-width: 1280px) {
  #home {
    #visual {
      .container {
        .title {
          padding: 0 10px;

          h1 {
            font-size: 2rem;
            margin-bottom: 1rem;
            font-weight: 800;
          }

          p {
            font-size: 1.2rem;
          }
        }

        .scrollDown {
          .lineBox {
            height: 100px;

            .line {
              height: 50px;
            }
          }
        }
      }
    }

    #solution {
      padding: 100px 0 150px;

      .bgLogo {
        .bgWrap {
          width: 1000%;

          span {
            font-size: 17rem;
          }
        }
      }
    }

    #record {
      padding: 100px 0 100px;
      .container {
        .license {
          margin-bottom: 100px;
        }
      }
    }

    #news {
      padding: 100px 0 180px;

      .container {
        .linkBox {
          top: unset;
          bottom: -50px;
          left: 3px;
          right: unset;
        }
      }
    }
  }
}

/* MOBILE */
@media screen and (max-width: 767px) {
  #home {
    #visual {
      .container {
        .title {
          padding: 0 10px;

          h1 {
            font-size: 1.95rem;
            margin-bottom: 1rem;
            font-weight: 800;
          }

          p {
            font-size: 1rem;
          }
        }

        .scrollDown {
          .lineBox {
            height: 100px;

            .line {
              height: 50px;
            }
          }
        }
      }
    }

    #solution {
      padding: 100px 0 150px;

      .bgLogo {
        .bgWrap {
          width: 1000%;

          span {
            font-size: 17rem;
          }
        }
      }
    }

    #news {
      padding: 100px 0 180px;

      .container {
        .linkBox {
          top: unset;
          bottom: -50px;
          left: 3px;
          right: unset;
        }
      }
    }
  }
}

@keyframes delayShow {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes lineDown {
  0% {
    transform: translateY(0%);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translateY(150%);
    opacity: 0;
  }
}

@keyframes titleUp {
  0% {
    transform: translateY(0%);
    opacity: 0;
  }
  100% {
    transform: translateY(-65%);
    opacity: 1;
  }
}
</style>
