<template>
    <header :class="[$route.meta.headerClass]" ref="appGnb">
        <div class="container">
            <h1>
                <router-link to="/"><img src="@/assets/images/logo.png" alt="라온로드로고" /><span class="hidden">라온로드</span></router-link>
            </h1>
            <nav class="gnb" @click.self="menuBtn()" :class="{ active: isActive }">
                <router-link to="/company">Company</router-link>
                <router-link to="/solution/ai"
                    >Solution
                    <ul class="depth">
                        <li><router-link to="/solution/ai">Artificial Intelligence</router-link></li>
                        <li><router-link to="/solution/bigdata">Big Data</router-link></li>
                        <li><router-link to="/solution/cloud">Cloud Service</router-link></li>
                        <li><router-link to="/solution/dtn">Digital Twin Network</router-link></li>
                    </ul>
                </router-link>
                <router-link to="/news">News</router-link>
                <router-link to="/notice">Notice</router-link>
                <div class="select">
                    <button class="label">
                        <svg xmlns="http://www.w3.org/2000/svg" height="16px" viewBox="0 0 24 24" width="16px" fill="#FFFFFF" style="margin-right: 5px">
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path
                                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zM4 12c0-.61.08-1.21.21-1.78L8.99 15v1c0 1.1.9 2 2 2v1.93C7.06 19.43 4 16.07 4 12zm13.89 5.4c-.26-.81-1-1.4-1.9-1.4h-1v-3c0-.55-.45-1-1-1h-6v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41C17.92 5.77 20 8.65 20 12c0 2.08-.81 3.98-2.11 5.4z"
                            />
                        </svg>
                        language
                    </button>
                    <ul class="translationList depth">
                        <li @click="this.translateReset" class="kolang" data-lang="kolang">한국어</li>
                        <li data-lang="en">English</li>
                        <li data-lang="de">Deutsch</li>
                        <li data-lang="es">Español</li>
                        <li data-lang="ar">ارابيكا</li>
                        <li data-lang="ru">Русский</li>
                        <li data-lang="zh-CN">简体中文</li>
                        <li data-lang="zh-TW">繁體中文</li>
                        <li data-lang="ja">日本人</li>
                    </ul>
                </div>
                <!-- <router-link to="/reference">Reference</router-link> -->
            </nav>

            <button class="menuBtn" @click="menuBtn()" :class="{ active: isActive }">
                <span></span>
                <span></span>
                <span></span>
            </button>
        </div>
    </header>
</template>

<script>
export default {
    name: "AppHeader",
    data() {
        return {
            isActive: false,
            isOn: false,
        };
    },
    mounted() {
        this.customSelect();
        document.querySelector(".translationList").addEventListener("click", function (event) {
            let el = event.target;
            if (el != null) {
                while (el.nodeName == "FONT" || el.nodeName == "SPAN" || el.nodeName == "P") {
                    el = el.parentElement;
                }
                const tolang = el.dataset.lang;
                const gtcombo = document.querySelector(".goog-te-combo");
                if (gtcombo == null) {
                    alert("ERROR! 찾을 수 없는 언어입니다.");
                    return false;
                }
                gtcombo.value = tolang;
                gtcombo.dispatchEvent(new Event("change"));
            }
            return false;
        });
    },
    methods: {
        menuBtn() {
            if (this.isActive === false) {
                this.isActive = true;
            } else {
                this.isActive = false;
            }
        },
        // 번역 리셋 함수
        translateReset() {
            var iframe = document.getElementsByTagName("iframe")[0];
            if (!iframe) return;
            var innerDoc = iframe.contentDocument || iframe.contentWindow.document;
            var restore = innerDoc.getElementById(":1.restore");
            restore.click();
        },
        // 번역 셀렉트 박스 커스텀 함수
        customSelect() {
            const label = document.querySelector(".label");
            const selectBox = document.querySelector(".select");
            const options = document.querySelectorAll(".translationList li");
            const handleSelect = (item) => {
                label.parentNode.classList.remove("active");
                if (item.className === "kolang") label.innerHTML = "한국어";
                else label.innerHTML = item.textContent;
            };

            options.forEach((option) => {
                option.addEventListener("click", () => handleSelect(option));
            });

            selectBox.addEventListener("click", () => {
                if (label.parentNode.classList.contains("active")) {
                    label.parentNode.classList.remove("active");
                } else {
                    label.parentNode.classList.add("active");
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/* DESKTOP */
header {
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10;
    transition: top 0.5s;
    backdrop-filter: blur(20px);

    &.show {
        top: 0px;
    }

    &.hidden {
        top: -100px;
    }

    &.ani {
        transform: translateY(-100%);
        animation: delayShow 0.5s 1.6s ease forwards;
    }

    .container {
        width: 1280px;
        margin: 0 auto;
        padding: 20px 0px 0px;
        display: flex;
        justify-content: space-between;

        h1 {
            width: 150px;
            margin: 0px;
            z-index: 11;
            padding-bottom: 20px;

            img {
                width: 100%;
            }
        }

        .gnb {
            display: flex;
            position: relative;
            z-index: 11;
            padding-bottom: 20px;

            a {
                padding: 10px 30px;
                color: #fff;
                transition: 0.5s;
                position: relative;

                .depth {
                    position: absolute;
                    top: 65px;
                    left: 50%;
                    transform: translate(-50%, -10%);
                    width: max-content;
                    background: #212121;
                    border-radius: 10px;
                    padding: 10px 0px;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.3s ease;

                    li {
                        padding: 5px 0px;

                        a {
                            font-size: 0.9rem;
                            font-weight: 200;

                            &:hover {
                                color: #fe6500;
                            }
                        }
                    }
                }

                &:hover {
                    color: #fe6500;

                    .depth {
                        opacity: 1;
                        visibility: visible;
                        transform: translate(-50%, 0px);
                    }
                }
            }
            .router-link-active {
                color: #fe6500;
            }

            .select {
                position: relative;
                min-width: 120px;
                width: max-content;
                height: fit-content;
                margin-top: 8px;
                padding: 5px 50px 5px 10px;
                cursor: pointer;
                border-radius: 4px;
                background: url("@/assets/images/keyboard_arrow_down_white_24dp.svg") calc(100% - 7px) center no-repeat;
                border: 1px solid rgba(255, 255, 255, 0.5);

                .label {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    color: #fff;
                }

                .translationList.depth {
                    position: absolute;
                    top: 50px;
                    left: 50%;
                    transform: translate(-50%, -10%);
                    width: max-content;
                    background: #212121;
                    border-radius: 10px;
                    padding: 10px;
                    opacity: 0;
                    visibility: hidden;
                    transition: all 0.3s ease;

                    li {
                        padding: 5px 0px;
                        color: #fff;
                        font-size: 0.9rem;
                        font-weight: 200;

                        &:hover {
                            color: #fe6500;
                        }
                    }
                }

                &.active .depth {
                    max-height: max-content;
                    opacity: 1;
                    visibility: visible;
                    transform: translate(-50%, 0px);
                }
            }
        }

        .menuBtn {
            display: none;
        }
    }
}

/* TABLET */
@media screen and (max-width: 1280px) {
    header {
        .container {
            width: 95%;
            align-items: center;
        }
    }
}

/* MOBILE */
@media screen and (max-width: 767px) {
    header {
        backdrop-filter: unset;
        animation: none;

        &::before {
            content: "";
            position: absolute;
            top: 0px;
            left: 0px;
            display: block;
            width: 100%;
            height: 100%;
            backdrop-filter: blur(20px);
        }

        .container {
            width: calc(100% - 80px);

            .gnb {
                display: flex;
                flex-direction: column;
                padding: 18vh 0px;
                width: 100%;
                height: 100vh;
                position: fixed;
                top: 0px;
                right: 0px;
                z-index: 10;
                backdrop-filter: blur(20px);
                transform: translateX(100%);
                transition: all 0.3s ease;

                &.active {
                    transform: translateX(0);
                }

                a {
                    padding: 10px 40px;
                    font-size: 2rem;
                    font-weight: 600;
                    margin-bottom: 40px;
                    transition: all 0.3s ease;

                    .depth {
                        position: unset;
                        transform: unset;
                        background: transparent;
                        border-radius: 0px;
                        opacity: 1;
                        visibility: visible;
                        padding: 0px;

                        li {
                            padding: 0px;

                            a {
                                font-size: 1.1rem;
                                font-weight: 200;
                            }
                        }
                    }

                    &:hover {
                        color: #fe6500;

                        .depth {
                            transform: unset;
                        }
                    }

                    &:hover,
                    &.router-link-active {
                        transform: translateX(5px);
                    }
                }

                .select {
                    position: absolute;
                    top: 4rem;
                    left: 42px;

                    .translationList.depth {
                        top: 35px;
                    }
                }
            }

            .menuBtn {
                display: block;
                width: 30px;
                height: 20px;
                position: relative;
                cursor: pointer;
                z-index: 11;
                margin-bottom: 20px;

                span {
                    position: absolute;
                    right: 0px;
                    display: block;
                    height: 3px;
                    background: #fff;
                    transition: all 0.6s;

                    &:nth-of-type(1) {
                        top: 0px;
                        width: 100%;
                    }

                    &:nth-of-type(2) {
                        top: 50%;
                        transform: translateY(-50%);
                        width: 70%;
                    }

                    &:nth-of-type(3) {
                        bottom: 0px;
                        width: 50%;
                    }
                }

                &:hover {
                    span {
                        width: 100%;
                    }
                }

                &.active {
                    span {
                        &:nth-of-type(1) {
                            top: 50%;
                            transform: translateY(-50%) rotate(45deg);
                        }

                        &:nth-of-type(2) {
                            top: 50%;
                            transform: translateX(-100%);
                            opacity: 0;
                        }

                        &:nth-of-type(3) {
                            bottom: 50%;
                            width: 100%;
                            transform: translateY(50%) rotate(-45deg);
                        }
                    }
                }
            }
        }
    }
}

@keyframes delayShow {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}
</style>
