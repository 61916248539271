<template>
    <div class="gnb" :class="{show : isShow}">
        <a href="https://blog.naver.com/laonroad"><span>Blog</span> <img src="@/assets/images/blog.svg"></a>
        <button @click="scrollTop()">
          <svg xmlns="http://www.w3.org/2000/svg" ><path d="M22.5 31.4H25.5V22.3L29.2 26L31.3 23.9L24 16.6L16.7 23.9L18.8 26L22.5 22.3ZM24 44Q19.75 44 16.1 42.475Q12.45 40.95 9.75 38.25Q7.05 35.55 5.525 31.9Q4 28.25 4 24Q4 19.8 5.525 16.15Q7.05 12.5 9.75 9.8Q12.45 7.1 16.1 5.55Q19.75 4 24 4Q28.2 4 31.85 5.55Q35.5 7.1 38.2 9.8Q40.9 12.5 42.45 16.15Q44 19.8 44 24Q44 28.25 42.45 31.9Q40.9 35.55 38.2 38.25Q35.5 40.95 31.85 42.475Q28.2 44 24 44ZM24 41Q31.25 41 36.125 36.125Q41 31.25 41 24Q41 16.75 36.125 11.875Q31.25 7 24 7Q16.75 7 11.875 11.875Q7 16.75 7 24Q7 31.25 11.875 36.125Q16.75 41 24 41ZM24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Q24 24 24 24Z"/></svg>
        </button>
    </div>
</template>

<script>
export default {
    name: 'AppScrollTop',
    data(){
      return {
        isShow : false
      }
    },
    mounted(){
      window.addEventListener('scroll', this.scrollShow);
    },
    destroyed(){
      window.removeEventListener('scroll', this.scrollShow);
    },
    methods : {
      scrollTop(){
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      },
      scrollShow(){
        if(window.scrollY > 100) {
          this.isShow = true;
        }else{
          this.isShow = false;
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.gnb {
  position: fixed;
  right: 2rem;
  bottom: 4.5rem;
  height: 3rem;
  transition: all 0.3s;
  opacity: 0;
  z-index: 9;

  &.show { opacity: 1; }

  a {
    display: block;
    width: 100%;
    height: 100%;
    color: rgba(255, 255, 255, 0.3);
    transition: all 0.2s;
    font-weight: 500;
    font-size: 1.2rem;
    position: relative;
    span { 
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.2s; 
    }
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-70%, -50%);
      width: 2rem;
      height: 2rem;
      opacity: 0;
      transition: all 0.2s;
    }
    &:hover {
        color: rgba(255, 255, 255, 1);
        span { opacity: 0; }
        img { opacity: 1; transform: translate(-50%,-50%);}
    }
  }

  button {
    width: 45px;
    height: 45px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.2);

    &:hover svg { color: #FE6500; }
    svg{
      display: inline;
      width: 100%;
      height: 100%;
      transition: 0.3s ease;
      color: rgba(255, 255, 255, 0.2);
      path {fill: currentColor; }
    }
  }
}


</style>