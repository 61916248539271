<template>
    <section class="company">
        <div class="container">
            <h1 class="tit">OUR COMPANY</h1>
            <h2>
                LAONROAD는 <span>A</span>I Vision, <span>B</span>ig Data, <span>C</span>loud, <span>D</span>igital Twin 기술로 미래를 열어갑니다. <br />
                더욱 안전하고 효율적인 이동, 지속가능한 이동 시스템, <span>LAONROAD</span>가 만듭니다.
            </h2>

            <div class="txtBox">
                <p>
                    LAONROAD는 코어 기술인 영상 분석 기반의 AI 전문 기업입니다. 국내 최초로 AI를 탑재한 교통 영상 카메라를 개발했으며, 경기 안양시 인덕원
                    사거리에서 AI 실시간 신호제어 시범 운영을 통해 AI 영상 분석 기반의 실시간 신호제어로 가는 문을 열었습니다. LAONROAD는 사람과 사람, 사람과
                    물건이 만나는 모든 여정에 안전하고 편리한 시스템을 구축하기 위해 노력하고 있습니다.
                </p>

                <Link-Box v-bind="{ text: 'VIEW OUR STORY', link: '/company' }" />
            </div>
        </div>
        <div id="bgImg"></div>
    </section>
</template>

<script>
import LinkBox from "@/components/LinkBox.vue";

export default {
    name: "OurCompany",
    components: {
        LinkBox,
    },
};
</script>

<style lang="scss" scoped>
.company {
    padding: 180px 0;
    position: relative;
    background: transparent;

    .container {
        h2 {
            color: #fff;
            font-size: 2rem;
            margin-bottom: 30px;
            text-shadow: 0 0 3px #fff;

            span {
                color: #fe6500;
                text-shadow: 0 0 3px #fe6500;
            }
        }

        .txtBox {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                width: 50%;
                font-size: 1rem;
                font-weight: 300;
                color: #fff;
                line-height: 1.65rem;
            }

            .linkBox {
                position: unset;
                width: 50%;
                justify-content: center;
                align-items: center;
            }
        }
    }

    #bgImg {
        width: 100%;
        height: 652px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            background: url("../../assets/images/chip.png") no-repeat center / contain;
            position: fixed;
            top: 0;
            left: 0;
            z-index: -1;
            opacity: 0.9;
        }
    }
}

/* TABLET */
@media screen and (max-width: 1280px) {
    .company {
        padding: 150px 0;

        .container {
            h2 {
                font-size: 1.45rem;
                br {
                    display: none;
                }
            }

            .txtBox {
                flex-direction: column;
                align-items: flex-start;

                p {
                    width: 100%;
                    margin-bottom: 30px;
                }

                .linkBox {
                    width: 90%;
                    justify-content: flex-start;
                    align-items: flex-start;
                }
            }
        }

        #bgImg {
            height: 700px;
        }
    }
}
</style>
