<template>
  <div class="linkBox">
    <router-link :to="{path: link}">{{ text }}</router-link>
    <div class="arrow"><font-awesome-icon icon="fa-solid fa-arrow-right-long" /></div>
  </div>
</template>

<script>
export default {
  name: "LinkBox",
  props: {
    text: String,
    link: String
  }
}
</script>