const anchorStore = {
  namespaced: true,
  state: {
    btnA: [
      { btnTo : 'tas', btnName: 'TAS'},
      { btnTo : 'vds', btnName: 'VDS'},
      { btnTo : 'aids', btnName: 'AIDS'},
      { btnTo : 'intersection', btnName: '스마트 교차로'},
      { btnTo : 'crosswalk', btnName: '스마트  횡단보도'},
      { btnTo : 'avoidance', btnName: '보행자 충돌방지'},
      { btnTo : 'deeplearning', btnName: '도로교통 AI'}
    ],
    btnB: [
      { btnTo : 'tms', btnName: 'TMS'},
      { btnTo : 'intersection', btnName: '스마트 교차로'},
      { btnTo : 'crosswalk', btnName: '스마트  횡단보도'},
      { btnTo : 'deeplearning', btnName: '도로교통 AI'}
    ],
    btnC: [
      { btnTo : 'tmaas', btnName: 'TMaaS'}
    ],
    btnD: [
      { btnTo : 'dtn', btnName: 'DTN'},
      { btnTo : 'hdmapldm', btnName: 'HD Map & LDM'},
    ]
  }
}

export default anchorStore;