var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"home"}},[_c('figure',{attrs:{"id":"visual"}},[_c('video',{staticClass:"vids",attrs:{"autoplay":"","loop":"","muted":"","playsinline":"","poster":require("@/assets/images/poster.jpg"),"src":require("@/assets/videos/video.mp4")},domProps:{"muted":true}}),_vm._m(0)]),_c('OurCompany'),_c('section',{attrs:{"id":"solution"}},[_c('div',{staticClass:"container"},[_c('Solution-List')],1),_c('div',{staticClass:"bgLogo"},[_c('div',{ref:"bgWrap",staticClass:"bgWrap"},[_c('span',[_vm._v("LAON ROAD")]),_c('span',[_vm._v("LAON ROAD")])])])]),_c('OurHistory'),_c('section',{attrs:{"id":"record"}},[_c('div',{staticClass:"container"},[_vm._m(1),_c('div',{staticClass:"license"},[_c('License-List')],1),_vm._m(2)])]),_c('section',{attrs:{"id":"news"}},[_c('div',{staticClass:"container"},[_vm._m(3),_c('Link-Box',_vm._b({},'Link-Box',{ text: 'VIEW MORE', link: '/news' },false)),_c('ul',{staticClass:"listTable"},_vm._l((_vm.newsletters.slice(0, 5)),function(letter,index){return _c('li',{key:index},[_c('span',{staticClass:"listNum",domProps:{"textContent":_vm._s(_vm.GetNumber - index < 10 ? ("0" + (_vm.GetNumber - index)) : _vm.GetNumber - index)}}),_c('router-link',{attrs:{"to":{ name: 'newsletter',
              params: {
                id: letter.id,
                date: letter.date,
                title: letter.title,
                content: letter.content,
                image: letter.image,
                orLink: letter.orLink,
              },}}},[_vm._v(_vm._s(letter.title))]),_c('span',{staticClass:"date"},[_vm._v(_vm._s(letter.date))])],1)}),0)],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"title"},[_c('h1',[_vm._v("ROAD TO A BETTER "),_c('span',[_vm._v("FUTURE")])]),_c('p',[_vm._v("We make mobility safer, more efficient, and more sustainable")])]),_c('div',{staticClass:"scrollDown"},[_c('span',[_vm._v("SCROLL DOWN")]),_c('div',{staticClass:"lineBox"},[_c('div',{staticClass:"line"})])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"tit2"},[_c('span',[_vm._v("LAONROAD의 증명된 가치")]),_vm._v("를 확인하세요.")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"certification"},[_c('h2',[_vm._v("CERTIFICATION")]),_c('ul',{staticClass:"listTable"},[_c('li',[_c('p',[_vm._v("한국지능형교통체계협회")]),_c('h3',[_vm._v("돌발상황 검지시스템 성능평가 [최상급]")]),_c('span',[_vm._v("2020-07-17")])]),_c('li',[_c('p',[_vm._v("한국건설기술연구원")]),_c('h3',[_vm._v("차량검지장치(영상식 VDS) 성능평가 [최상급]")]),_c('span',[_vm._v("2019-19-11")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h1',{staticClass:"tit2"},[_c('span',[_vm._v("LAONROAD의 주요 소식")]),_vm._v("을 확인하세요.")])}]

export { render, staticRenderFns }