<template>
  <div id="app" @wheel="wheelHeader" >
    <AppHeader ref="appHeader" />
    <router-view/>
    <AppScrollTop />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './components/common/AppHeader.vue';
import AppFooter from './components/common/AppFooter.vue';
import AppScrollTop from './components/common/AppScrollTop.vue';

export default {
  name: 'app',
  components: {
    AppHeader,
    AppFooter,
    AppScrollTop
  },
  data(){
    return{
      scrollY: window.scrollY || window.pageYOffset
    }
  },
  mounted(){
    window.addEventListener('scroll',this.fixedHeader);
  },
  destroyed(){
    window.removeEventListener('scroll', this.fixedHeader);
  },
  methods:{
    wheelHeader(){
      const header = this.$refs.appHeader.$refs.appGnb;
      window.addEventListener('wheel', function(e){
        if(e.deltaY < 0){
          header.classList.add('show')
          header.classList.remove('hidden')
        }else if (e.deltaY > 0 ){
          header.classList.add('hidden')
          header.classList.remove('show')
        }
      })
    },
    fixedHeader(){
      const header = this.$refs.appHeader.$refs.appGnb;
      if(window.scrollY === 0){
        header.classList.remove('hidden')
      }
    }
  }
}
</script>

<style lang="scss">
@import "./assets/scss/reset.scss";
@import "./assets/scss/common.scss";
</style>