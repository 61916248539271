import Vue from "vue";
import Vuex from "vuex";

import solutionStore from "@/store/modules/solutionStore.js";
import homeStore from "@/store/modules/homeStore.js";
import newsroomStore from "@/store/modules/newsroomStore.js";
import noticeStore from "@/store/modules/noticeStore.js";
import anchorStore from "@/store/modules/anchorStore.js";

import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
    strict: process.env.NODE_ENV !== "production",
    state: {},
    mutations: {},
    actions: {},
    modules: {
        solutionStore: solutionStore,
        homeStore: homeStore,
        newsroomStore: newsroomStore,
        noticeStore: noticeStore,
        anchorStore: anchorStore,
    },
    plugins: [
        createPersistedState({
            storage: window.localStorage,
            paths: ["newsroomStore", "noticeStore"],
        }),
    ],
});
