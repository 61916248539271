const solutionStore = {
  namespaced: true,
  state: {
    solutions: [
      {
        alphabet: 'A',
        title: 'rtificial Intelligence',
        address: '/solution/ai',
        content: '라온로드 AI는 심층 인공 신경망과 첨단 딥러닝 기술로 도로 위 차량과 사람을 정확하게 인식합니다.눈, 비, 안개, 교차로, 횡단보도, 고속도로 등 여러 상황에 맞게 최적의 알고리즘을 적용해 교통 정보와 돌발 상황, 보행자 위험 정보를 정확하고 빠르게 파악합니다.',
        tags: [
          {
            link: '/solution/ai',
            tag: 'TAS (교통분석시스템)'
          },
          {
            link: '/solution/ai',
            tag: 'VDS (차량검지시스템)'
          },
          {
            link: '/solution/ai',
            tag: 'AIDS (돌발상황검지시스템)'
          },
          {
            link: '/solution/ai',
            tag: '스마트 교차로 시스템'
          },
          {
            link: '/solution/ai',
            tag: '스마트 횡단보도 시스템'
          },
          {
            link: '/solution/ai',
            tag: '도로교통 AI 학습시스템'
          },
          {
            link: '/solution/ai',
            tag: 'C-ITS (보행자 충돌방지 시스템)'
          }
        ]
      },
      {
        alphabet: 'B',
        title: 'ig Data',
        address: '/solution/bigdata',
        content: '스마트 모빌리티 시대, AI 기술은 엄청난 양의 정보를 쏟아냅니다. 수많은 차량과 보행자의 이동 궤적, 통행 정보, 차종, 차량번호, 속도, 방향등 예전에 없던 대용량의 IoT 데이터가 매 순간 늘어나고 있습니다. 라온로드는 도로 위의 다양한 정보를 수집 정제하고 복합 데이터를 구성해 도시 교통 운영자가 바르게 판단할 수 있도록 시각화 데이터 분석 도구를 제공합니다. 차량의 시계열 통행량, 교차로 서비스 수준(LOS), 지체 정보 등 다양한 교통 상황을 직관적으로 파악할 수 있습니다.',
        tags: [
          {
            link: '/solution/bigdata',
            tag: 'TMS (교통관리시스템)'
          },
          {
            link: '/solution/bigdata',
            tag: '스마트 교차로 시스템'
          },
          {
            link: '/solution/bigdata',
            tag: '스마트 횡단보도 시스템'
          },
          {
            link: '/solution/bigdata',
            tag: '도로교통 AI 학습시스템'
          }
        ]
      },
      {
        alphabet: 'C',
        title: 'loud Service',
        address: '/solution/cloud',
        content: '지금까지 교통시스템은 관리 주체인 지방자치단체에 on-premise로 구축되었습니다. 하지만 스마트 모빌리티의 폭증하는 데이터를 모으고 연결하면 더욱 큰 가치를 만들 수 있습니다. 교통 상황에 대해 더욱 전문적이고 깊이 있는 통찰을 얻을 수 있습니다. 도시와 도시의 정보를 연결해 더 좋은 교통 정책, 더 나은 환경을 만들 수 있습니다.',
        tags: [
          {
            link: '/solution/cloud',
            tag: 'TMaaS 클라우드 교통관리 서비스'
          }
        ]
      },
      {
        alphabet: 'D',
        title: 'igital Twin Network(DTN)',
        address: '/solution/dtn',
        content: '도로 위 모든 이동은 흔적을 남깁니다. 라온로드 DTN은 도로 위 움직이는 객체의 이동류를 가상 세계에 기록하고 분석합니다. 이동 궤적 모델링과 시뮬레이션을 통해 도시 교통 흐름을 이해하고 최적 신호 제어, 교통 영향 평가, 가상 실험을 수행합니다. 라온로드 DTN은 자율주행을 완성하는 자율협력주행 플랫폼을 지향합니다.',
        tags: [
          {
            link: '/solution/dtn',
            tag: 'DTN (디지털 트윈 네트워크)'
          },
          {
            link: '/solution/dtn',
            tag: 'HD Map & LDM'
          }
        ]
      }
    ],
    btns: [
      {
        letter: 'A',
        naviTitle: 'rtificial Intelligence'
      },
      {
        letter: 'B',
        naviTitle: 'ig Data'
      },
      {
        letter: 'C',
        naviTitle: 'loud Service'
      },
      {
        letter: 'D',
        naviTitle: 'igital Twin Network(DTN)'
      }
    ]
  }
}

export default solutionStore;