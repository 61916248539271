<template>
  <div class="scrollWrap">

    <!-- DESKTOP / TABLET -->
    <div class="naviBox">
      <h1 class="tit">OUR SOLUTION</h1>
      <button v-for="(btn, btnIdx) in btns" :key="btnIdx" ref="btnIdx" @click="goto(btnIdx)"><span>{{ btn.letter }}</span>{{ btn.naviTitle }}</button>
    </div>
    <ul class="contents">
      <li v-for="(solution, alphabet) in solutions" ref="alphabet" :key="alphabet">
        <div class="imgBox">
          <h2><span>{{ solution.alphabet }}</span>{{ solution.title }}</h2>
          <p>{{ solution.content }}</p>
        </div>
        <ul class="contentsTag">
          <li v-for="(product, productIdx) in solution.tags" :key="productIdx">
            <router-link :to="{path: product.link}">{{ product.tag }}</router-link>
          </li>
        </ul>
        <div class="linkBox">
          <router-link :to="{path: solution.address}">관련 제품 바로 보기</router-link>
          <div class="arrow"><font-awesome-icon icon="fa-solid fa-arrow-right-long" /></div>
        </div>
      </li>
    </ul>

    <!-- MOBILE -->
    <h1 class="tit titMb">OUR SOLUTION</h1>
    <ul class="contentsMb">
      <li v-for="(solution, alphabet) in solutions" :key="alphabet">
        <div class="imgBox">
          <h2><span>{{ solution.alphabet }}</span>{{ solution.title }}</h2>
        </div>
        <p>{{ solution.content }}</p>
        <ul class="contentsTag">
          <li v-for="(product, productIdx) in solution.tags" :key="productIdx">
            <router-link :to="{path: product.link}">{{ product.tag }}</router-link>
          </li>
        </ul>
        <div class="linkBox">
          <router-link :to="{path: solution.address}">관련 제품 바로 보기</router-link>
          <div class="arrow"><font-awesome-icon icon="fa-solid fa-arrow-right-long" /></div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

const solutionStore = 'solutionStore'

export default {
  name: "SolutionList",
  mounted(){
    window.addEventListener('scroll', this.btnActive);
  },
  destroyed(){
    window.removeEventListener('scroll', this.btnActive);
  },
  methods: {
    // 솔루션 섹션의 앵커 버튼 클릭시 해당 제품으로 스크롤 이동 함수
    goto(index){
      const posArr= [];
      const scroll= window.scrollY || window.pageYOffset;
      const setIdx= this.$refs.alphabet;

      for(let el of setIdx){
        const elPos = el.getBoundingClientRect();
        const startPos = elPos.top;
        const baseLine = 200
        posArr.push(scroll + startPos - baseLine);
      }

      window.scrollTo({
        top: posArr[index],
        left: 0,
        behavior: 'smooth'
      });
    },

    // 스크롤 위치값에 따라 해당 제품 범위 안에 스크롤 이동 시 앵커 버튼 활성화
    btnActive(){
      const scroll= window.scrollY || window.pageYOffset;
      const posArr= [];
      const setIdx= this.$refs.alphabet;
      const btns = this.$refs.btnIdx;

      for(let el of setIdx){
        const elPos = el.getBoundingClientRect();
        const startPos = elPos.top;
        const baseLine = 400
        posArr.push(scroll + startPos - baseLine);
      }

      for(let [index] of setIdx.entries()){
        if(scroll >= posArr[index]){
          for(let [i] of btns.entries()){
            btns[i].classList.remove('active');
          }
          btns[index].classList.add('active');
        }
      }
    }
  },
  computed: {
    ...mapState(solutionStore, ['solutions','btns'])
  }
}
</script>

<style lang="scss" scoped>
.scrollWrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;

  .titMb {
    display: none;
  }

  .contentsMb {
    display: none;
  }

  .naviBox {
    width: 250px;
    height: 140px;
    position: sticky;
    top: 170px;
    left: 0px;
    margin-bottom: 155px;
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 50px;
    }

    button {
      width: fit-content;
      display: inline-block;
      margin-bottom: 10px;
      font-size: 1.05rem;
      font-weight: 300;
      color: #fff;
      position: relative;
      transition: .3s;
      letter-spacing: 1px;
      cursor: pointer;

      &:hover, &.active {
        color: #FE6500;
        transform: translateX(5px);
      }

      span {
        font-weight: 600;
      }
    }
  }

  .contents {
    width: calc(100% - 400px);
    margin-top: 80px;

    li {
      width: 100%;
      margin-bottom: 100px;
      
      .imgBox {
        width: 100%;
        height: 400px;
        border-radius: 10px;
        background: rgba(0,0,0,0.2);
        padding: 30px;
        position: relative;
        margin-bottom: 20px;
        
        &::before {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0px;
          left: 0px;
          border-radius: 10px;
        }

        h2 {
          color: #fff;
          font-weight: 400;
          font-size: 1.7rem;
          position: relative;
          z-index: 2;

          span {
            color: #FE6500;
            font-weight: 700;
          }
        }

        p {
          width: 80%;
          position: absolute;
          bottom: 30px;
          color: #fff;
          font-weight: 300;
          font-size: 1rem;
          line-height: 1.65rem;
        }
      }

      &:nth-of-type(1) .imgBox::before {
        background: url('../assets/images/img_1.jpg') no-repeat center / cover;
      }

      &:nth-of-type(2) .imgBox::before {
        background: url('../assets/images/img_2.jpg') no-repeat center / cover;
      }

      &:nth-of-type(3) {
        .imgBox{
          background: rgba(0,0,0,0.3);
          p { text-shadow: 0 0 3px rgba(255,255,255, 0.8);}
        }
        .imgBox::before { background: url('../assets/images/img_3.png') no-repeat center / cover; }
      }

      &:nth-of-type(4) {
        .imgBox{
          background: rgba(0,0,0,0.3);
          p { text-shadow: 0 0 3px rgba(255,255,255, 0.8);}
        }
        .imgBox::before { background: url('../assets/images/img_4.png') no-repeat center bottom / cover;}
      }

      .contentsTag {
        width: 100%;
        margin-bottom: 20px;
        
        li {
          display: inline-block;
          width: auto;
          margin: 0 10px 10px 0;
          padding: 5px 20px;
          border-radius: 30px;
          border: 1px solid rgba(255,255,255, 0.4);
          cursor: pointer;
          transition: all 0.5s ease;

          a {
            color: #fff;
            font-size: 0.8rem;
            line-height: 1.6rem;
          }

          &:hover {
            border: 1px solid #FE6500;
            background: #FE6500;
          }
        }
      }

      .linkBox {
        position: unset;
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .scrollWrap {
    .contents {
      width: calc(100% - 250px);

      li {
        .contentsTag {
          li {
            display: inline-block;
            width: 100%;
            text-align: center;
          }
        }
      }
    }
  }
}
/* MOBILE */
@media screen and (max-width: 767px) {
  .scrollWrap {
    flex-direction: column;
    
    .naviBox {
      display: none;
    }

    .contents{
      display: none;
    }

    .titMb {
      display: block;
    }

    .contentsMb {
      display: block;
      margin-top: 0px;

      li {
        width: 100%;
        margin-bottom: 100px;

        .imgBox {
          height: 200px;
          border-radius: 10px;
          background: rgba(0,0,0,0.2);
          padding: 20px;
          position: relative;
          margin-bottom: 20px;
          
          &::before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0px;
            left: 0px;
            border-radius: 10px;
          }

          h2 {
            color: #fff;
            font-weight: 400;
            font-size: 1.7rem;
            position: absolute;
            left: 20px;
            bottom: 20px;
            z-index: 2;

            span {
              color: #FE6500;
              font-weight: 700;
            }
          }
        }

        p {
          width: 95%;
          color: #fff;
          font-weight: 300;
          font-size: 0.95rem;
          line-height: 1.5rem;
          margin: 30px 0;
        }

        &:nth-of-type(1) .imgBox::before {
          background: url('../assets/images/img_1.jpg') no-repeat center / cover;
        }

        &:nth-of-type(2) .imgBox::before {
          background: url('../assets/images/img_2.jpg') no-repeat center / cover;
        }

        &:nth-of-type(3) {
          .imgBox{
            background: rgba(0,0,0,0.3);
            p { text-shadow: 0 0 3px rgba(255,255,255, 0.8);}
          }
          .imgBox::before { background: url('../assets/images/img_3.png') no-repeat center / cover; }
        }

        &:nth-of-type(4) {
          .imgBox{
            background: rgba(0,0,0,0.3);
            p { text-shadow: 0 0 3px rgba(255,255,255, 0.8);}
          }
          .imgBox::before { background: url('../assets/images/img_4.png') no-repeat center bottom / cover;}
        }

        .contentsTag {
          width: 100%;
          margin-bottom: 30px;
          
          li {
            display: block;
            /* width: fit-content; */
            margin-bottom: 15px;
            padding: 5px 20px;
            border-radius: 30px;
            border: 1px solid rgba(255,255,255, 0.4);
            cursor: pointer;
            transition: all 0.5s ease;
            text-align: center;

            a {
              color: #fff;
              font-size: 0.8rem;
              line-height: 1.6rem;
            }

            &:hover {
              border: 1px solid #FE6500;
              background: #FE6500;
            }
          }
        }

        .linkBox {
          position: unset;
        }
      }
    }
  }
}
</style>